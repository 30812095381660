<template>
    <div>
        <div v-if="loading">Loading</div>
        <div v-else-if="!loading && !item">Ürün bulunamadı.</div>
        <p-form :item="item" v-else-if="item" />
    </div>
</template>

<script>
import {getUserData} from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import pForm from './pForm'
export default {
    name: 'edit',
    props: {
        productId: Number
    },
    data(){
        return {
            item: false,
            loading: true,
            userData: getUserData()
        }
    },
    components: {
        pForm
    },
    beforeCreate() {
        const productId = this.$route.params.product
        const t = this
        t.$http.get('product/showProduct/'+productId).then(response=>{
            t.loading = false
            const {data} = response
            if(data.status && data.data.companyId === t.userData.companyId){
                this.item = data.data
            }else if(data.message){
                t.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Hata`,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: data.message,
                    },
                })
            }
        });
    }
}
</script>

<style scoped lang="scss">

</style>
